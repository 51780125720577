@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600,800);
/*
  Box-sizing fix
  https://www.paulirish.com/2012/box-sizing-border-box-ftw/
 */
html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

/*
  Site wide styles
 */
body {
  margin: 0;
  padding: 0;

  background: #f8f8f8;

  color: #464646;
}

@media print {
  body {
    /* Don't waste ink on a background color when printing. */
    background: #ffffff;
  }
}

body,
input,
button {
  font-family: 'Open Sans', sans-serif;
}

#root {
  /* Make this element exactly the height and width of the viewport at all */
  /* times, regardless of any fixed-position elements. Any scrolling will */
  /* occur within children. */
  position: absolute;
  height: 100%;
  width: 100%;
}

@media print {
  #root {
    /* Must remove position: absolute to allow page breaks in Chrome. */
    position: static;
  }
}

/*
  Site wide text styles
*/
.center {
  text-align: center;
}

.plainlink {
  cursor: pointer;
  color: inherit;
  text-decoration: none;
}

/* this particular icon seems like it's the wrong size from fa */
.fa-university:before {
  top: -3px;
  left: 2px;
  position: relative;
}

h1 {
  font-size: 30px;
  margin-top: 12px;
  margin-bottom: 12px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #283044;
}

html a, html a:hover {
  /* should match theme.colors.primary */
  color: #1C9DE3;
  font-weight: 600;
}

code {
  /* Don't break "variable names" or other devvy text on mid-word hyphens. */
  white-space: nowrap;
}

strong {
  font-weight: 600;
}

/* Add to scenes that you'd like to have the space tile appear */
.LaunchPage {
  background: #3faeeb url(/static/media/login_clouds.414537eb.png) repeat-x bottom;
  overflow-x: overlay;
}

/* Override some styles that come with antd. */
html .ant-tooltip-inner {
  word-break: break-word;
}

html .ant-input {
  height: 37px;
}

/* TODO MOVE THESE STYLES TO A BETTER LOCATION */
.ContentWrapper {
  display: flex;
  margin: 16px 20px;
}

.ContentMain {
  width: 900px;
  border: 1px solid #464646;
  background-color: #ffffff;
}

.ContentOptions {
  width: 320px;
}

